.list-invoices-container {
  padding: 0 10px;
}
.list-invoices-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}


.list-invoices-filter {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.list-invoices-filter form {
  width : 220px
}

.invoice-filter-form {
  display: grid;
  grid-template-columns: 1fr;
  margin-right: -15px;
}

.invoice-filter-form div {
  width: 120px;
}

.search-p {
  width: 20%;
}

.list-invoice-table-head {
  background-color: rgb(194, 194, 194);
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 3fr 40px;
  grid-template-areas: "A-check A-code A-vendor A-created-at";
  padding: 10px 0;
}
.list-invoice-table-check {
  grid-area: A-check;
}
.list-invoice-table-code {
  grid-area: A-code;
}
.list-invoice-table-vendor {
  grid-area: A-vendor;
}
.list-invoice-table-created-at {
  grid-area: A-created-at;
}
