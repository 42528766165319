.supplier-authority-top-vendors {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.supplier-authority-top-vendors-table {
    width: 80%;
    margin-top: 1rem;
    span {
        font-weight: bolder !important;
        font-size: 1rem !important;
        cursor: pointer;
    }
}

.supplier-authority-top-vendors-table .ant-table-tbody > tr.last-row > td {
    background-color: #f0f0f0 !important; 
    border-color: #d9d9d9 !important;
}
  
.supplier-authority-top-vendors-table .ant-table-tbody > tr.last-row > td:first-child {
    background-color: #f0f0f0 !important; 
    border-color: #d9d9d9 !important;
}

.supplier-authority-header {
    text-align: left;
    padding: 10px;
}
