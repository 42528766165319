.tag-selector-container {
    border: 1px solid black;
  
    .tag-selector-header {
        border-bottom: 0.5px solid black;
        padding: 1rem;
        margin: 0;
    }
  
    .tag-selector {
        display: flex;
        flex-direction: column;
  
  
        .multiple-tag-selection-container {
            flex-grow: 1;
            flex-basis: 100%;
            padding: 1rem;
            
            .multiple-tag-selection {
                display: flex;
                flex-wrap: wrap;

                .tag-selector-select-container {
                    flex-shrink: 1;
                    flex-grow: 1;
                    flex-basis: 4rem;
                    margin: 0.5rem;
                }
            }
        }

        .multi-container-tag-selector {
            display: flex;
            flex-wrap: wrap;
            flex-grow: 1;
            height: max-content;
            flex-basis: 100%;
            padding: 1rem;
            justify-content: center;
            gap: 0.5rem;
            row-gap: 2rem;
            border-bottom: 0.5px solid black;
            
            .multi-item-tag-selector {
                border: 1px solid black;
                border-radius: 2px;
                width: 100px;

                .multi-item-tag-type {
                    color: rgb(172, 77, 77);
                    text-align: center;
                    text-shadow: 0px 0px 1px black;
                }
            }

            .grouped {
                margin-left: 2rem;
            }
    
        }
    }
}
  