.reviews-main-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 4rem;
    gap: 6rem;
}

.reviews-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 60%;
}


.general-review-info-main-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
}

.vendor-all-ratings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.vendor-total-rating {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    cursor: pointer;
}

.single-review-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
    border-bottom: 1px solid gray;
    padding-bottom: 1rem;
    text-align: left;
}

.review-star .ant-rate-star-full,
.review-star .ant-rate-star-half {
  color: #532d3c;
}

.review-star .ant-rate-star {
    cursor: pointer;
}

.review-customer-name-and-date-separator {
    width: 0;
    height: 1.2rem;
    margin: 0 0.5rem;
    border: 0.2px solid gray;
}


.review-name-and-date {
    display: flex;
    flex-direction: row;
}

.review-customer-country-image {
    margin-top: 0.25rem;
    margin-right: 0.5rem;
}

.review-question-yes {
    color: green;
}

.review-question-no {
    color: red;
}

.review-related-product {
    color: gray;
    cursor: pointer;
}

.review-product-images-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    cursor: pointer;
}

.review-product-img-wrapper {
    width: 100px;
    height: 100px;
    overflow: hidden; 
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.review-product-img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 0.3rem;
    border: 0.05px solid lightgray;
    
}

.single-rating {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    cursor: pointer;
    padding: 0.2rem;
    transition: background-color 0.3s, transform 0.3s;
}

.selected-single-rating {
    background-color: #f39c12; 
    transform: scale(1.1);

    border-radius: 0.5rem;
}

.review-container {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    gap: 0.7rem;
    font-size: 0.9rem;
    background-color: #eee;
    border-radius: 0.3rem;
    margin: 0.5rem 0;
    align-items: flex-start;

    .message-icon {
        margin-top: 5px;
    }
}

.see-original-text {
    color: gray;
    margin-left: 0.5rem;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.8rem;
    text-wrap: nowrap;
}

.review-filter-container {
    width: 100%;
    padding: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 3rem;
}

.single-filter {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.single-filter .ant-select {
    width: 100%;
}

.single-filter  .anticon-question-circle {
    margin-left: 8px;
    font-size: 16px;
    color: green;
}


.review-top-vendors {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.top-vendors-table {
    width: 50%;
    margin-top: 1rem;
    span {
        font-weight: bolder !important;
        font-size: 1rem !important;
        cursor: pointer;
    }
}
