.top-bar {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.form-bar {
  display: flex;

}

.form-label-bar-order-products {
  display: flex;
}

.search-bar {
  margin: 0px 10px;
  border: 1px solid black;
  width: 60%;
}

.search-bar-empty {
  margin: 0px 10px;
  width: 60%;
}

.save-btn {
  margin: 0px 5px;

  flex-grow: 1;
}

.discard-btn {
  margin: 0px 5px;

  flex-grow: 1;
}

.product-container {
  border: 1px solid black;
  box-sizing: border-box;
  display: flex;
  height: 500px;
  margin: 10px;
}


.delete-button {
  display: block;
  padding: 0px 5px;
  height: 25px;
  margin: 5px;
  border: 1px solid red;
  color: red;
  background-color: transparent;
  cursor: pointer;
  margin-left: 110px;
}

.delete-button:hover {
  background-color: red;
  color: white;
}

.clone-button {
  display: block;
  padding: 0px 5px;
  height: 25px;
  margin: 5px;
  border: 1px solid green;
  color: green;
  background-color: transparent;
  cursor: pointer;
  margin-left: 110px;
}

.clone-button:hover {
  background-color: green;
  color: white;
}

.form-bar-select {
  margin: 0px 5px;
  width: 200px;
}


.product-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin: 1rem;
  position: relative;
}
.same-row-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  grid-column: span 3;
  flex-wrap: wrap;
}

.same-row-container > div,
.same-row-container > span {
  flex: 1;
  min-width: 150px;
}


.product-info-item {
  width: 210px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}



.product-notes {
  padding: 5px;
}

.product-cogs {
  padding: 5px;
  margin-top: 10px;
}

.product-unit {
  padding: 5px;
  margin-top: 10px;
}

.product-sum {
  padding: 5px;
  margin-top: 10px;
}

.product-quantity {
  padding: 5px;
  margin-top: 10px;
}

.product-series {
  padding: 5px;
  margin-top: 10px;
}

.product-status {
  padding: 5px;
}
.product-payment-at {
  padding: 5px;
}

.product-count {
  padding: 5px;
  margin-top: 10px;
}

.product-id {
  padding: 5px;
}


.product-info-price {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.product-info-label {
  opacity: 70%;
}

.product-name {
  margin: 5px 20px 15px;
  display: grid;
  gap: 30px;
}

.product-name span {
  margin: 0px -10px;
}

.discounted-tag {
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: rgb(177, 122, 3);
  position: absolute;
  right: 155px;
  top: -21px;
}

.product-name-five {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr; 
}

.product-name-four {
  grid-template-columns: 3fr 3fr 3fr 1fr;
}

.product-name-three {
  grid-template-columns: 1fr 1fr 1fr;
}

.product-name-two .ant-input {
  width: 310px;
}

.customer-name {
  width: 150px;
  padding: 10px;
}

.vendor-name {
  width: 150px;
  padding: 10px;
}

.product-image {
  height: 100%;
  width: auto;
  object-fit:fill;
  margin-left: -10px;
}

.product-image-container {
  width: 100%;
  overflow: hidden;
  margin-right: 10px;
  max-width: 350px;
}

.product-price-text {
  text-align: left;
}

.product-supplier-link {
  margin-top: 5px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.product-group-item:hover{
  background-color: rgb(248, 225, 225);
}
.product-group-item {
    cursor: pointer;
}

.product-coupon {
  margin-top: 5px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}


.changed {
  border: 1px solid Chartreuse;
}