.item-image {
    height: 250px;
    overflow: hidden;
}

.item-image img {
    height: 100%;
    width: auto;
    object-fit: contain;
}

.all-product-container {
    height: 370px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.all-item-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.all-info-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.all-product-info-item {
    width: 220px !important;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.all-form-bar {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
}
  
.all-form-label-bar {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
}

.search-line {
    width: 70%;
    margin-bottom: 20px;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.form-bar {
  display: flex;
  justify-content: space-between;
}

.form-bar > .ant-select > .ant-select-arrow {
top: 17px;
}

.form-label-bar{
    display: flex;
    justify-content: flex-end;
}