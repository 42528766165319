.container {
    padding: 0 10px;
}

.form-top-supplier {
    width: 100%;
    border: 1px solid black;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.inline-inputs-supplier {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px
}

.supplier-form {
    width: 100%;
}

.founding-year {
    width: 100%;
}

.image {
    width: 100%;
    padding: 20px;
    margin: auto;
} 

.image-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-top: 1px solid black;
    margin-top: 20px;
    padding-top: 20px;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.custom-file-upload input[type="file"] {
    display: none;
}

.order-emails-header {
    font-weight: 700;
    text-align: left;
}

.saved-order-emails {
    margin-bottom: 30px;
    width: 66.33%;
}

.new-order-email {
    margin-bottom: 30px;
}

.new-order-email > p {
    text-align: left;
}

.new-order-input {
    width: calc(100% + 140px);
    text-align: left;
}

.header-save {
    display: flex;
    flex-direction: row;
    justify-content: flex-end !important;
}

.product-type-size-chart-images {
    padding: 1rem;
    border-top: 1px solid black; 

    .product-type-size-chart-images-header {
        text-align: left;
        margin-bottom: 1rem;
    }

    .product-type-size-chart-add-new {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        height: 2rem;
        margin: 2rem 0;
        align-items: center;

        .ant-form-item {
            margin: 0;
        }

        .ant-select-selector {
            width: 300px;
        }
    }

    .product-type-size-chart-images-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;

        .product-type-size-chart-image-container {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid rgb(226, 226, 226); 
            border-radius: 0.3rem;
            gap: 0.3rem;
            position: relative;

            .product-type-size-chart-image-trash {
                position: absolute;
                top: 0.2rem;
                right: 0.2rem;
                padding: 0.5rem;
                border-radius: 50%;
                width: 2rem;
                height: 2rem;
                border: 1px solid grey;
                display: flex;
                align-items: center;
                cursor: pointer;

                &:hover {
                    background-color: red;
                    color: white;
                    border-color: white;
                }
            }

            .product-type-size-chart-image {
                width: 200px;
                height: 300px;
                display: flex;
                align-items: center;

                img {
                    width: 200px;
                    object-fit: contain;
                }
            }
        }
    }
}

.vendor-image-box-container {
    border-top: 1px solid black;
    padding: 2rem 0;
}