.coupon-container {
    height: 100px;
    flex-direction: column;
    border: 1px solid black;
    box-sizing: border-box;
    display: flex;
    margin: 10px;
}

.coupon-label-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
    font-weight: 600;
}

.coupon-info-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
}

.used-coupon-form-label-bar {
    display: flex;
    padding: 0 10px;
}

.used-filter-container {
    width: 25%;
}