.layout-nav {
  height: 45px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  border-bottom: 1px solid black;
}
.main-layout {
  display: grid;
  min-height: 100vh;
  column-gap: 60px;
  row-gap: 10px;
  grid-template-columns: 225px 1fr;
  grid-template-areas: 'aside section';
  padding-bottom: 50px;
}

.buttons-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 5px;
  margin-right: -30px;
}


.layout-aside {
  grid-area: aside;
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.layout-aside a {
  display: inline-block;
  width: 100%;
}
.layout-aside button {
  width: 100%;
  text-align: start;
}

.layout-section {
  grid-area: section;
  padding-bottom: 15px;
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  text-align: center;
}

.custom-collapse .ant-collapse-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 4px 15px !important;
  
}

.custom-collapse .ant-collapse-expand-icon {
  order: 1;
}

.custom-collapse {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.custom-collapse .ant-collapse-content-box {
  display: flex;
  flex-direction: column;
  gap: 4px !important;
  padding: 0 !important;
}

.custom-collapse-button {
  padding-left: 32px !important;
}