.edit-user {
    padding: 20px;
}

.user-header-save {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(196, 196, 196);;
}