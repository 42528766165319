.list-products-container {
  padding: 0 10px;
}
.list-products-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-number-of-products {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
} 

.list-products-filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.product-filter-form {
  display: grid;
  grid-template-columns: 1fr;
  margin-right: -15px;
}

.product-filter-form div {
  width: 120px;
}

.search-p {
  width: 40% !important;
}

.list-product-table-head {
  background-color: rgb(194, 194, 194);
  display: grid;
  grid-template-columns: 1fr 3fr 8fr 4fr 4fr 4fr 4fr 2fr 2fr 4fr;
  grid-template-areas: " A-check . A-product A-status A-type A-tag A-vendor A-price. A-code";
  padding: 10px 0;
}
.list-product-table-check {
  grid-area: A-check;
}
.list-product-table-product {
  grid-area: A-product;
}
.list-product-table-status {
  grid-area: A-status;
}
.list-product-table-type {
  grid-area: A-type;
}
.list-product-table-tag {
  grid-area: A-tag;
}
.list-product-table-vendor {
  grid-area: A-vendor;
}
.list-product-table-price {
  grid-area: A-price;
}
.list-product-table-code {
  grid-area: A-code;
}
