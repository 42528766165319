.invoices-container {
    padding: 0 20px;
    height: inherit;
  
}

.inputs-invoice {
    width: 100%;
    display: grid;
    gap: 10px;
    height: 100%;
    padding: 20px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
}


.header-save {
    display: flex;
    flex-direction: row;
    justify-content: flex-end !important;
}


.order-product-add {
    margin-top: 5px;
    display: flex; 
    align-items: center; 
  }
  
  .order-product-add-bar {
    width: 750px;
  }
  
  .order-product-add button {
    margin-left: 5px;
  }

  .add-invoice-image-container{
    margin-top: 10px;
    width: 100%;
    gap: 10px;
    height: 100%;
    padding: 20px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
}

.all-cart-items-container{

  height:100%;
  margin-top: 10px;
  width: 100%;
  gap: 10px;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
}
.all-cart-items-filter{
  display: flex;
  justify-content: end;
  
}

.all-cart-items-sub-container{
  overflow-y: scroll;
  height:400px;
  margin-top: 10px;
  width: 100%;
  gap: 10px;
  padding: 20px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
}


.all-invoice-items-container{
    margin-top: 10px;
    width: 100%;
    gap: 10px;
    height: 100%;
    padding: 20px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
}

.invoice-item-container {
    border: 1px solid black;
    box-sizing: border-box;
    display: flex;
    height: 200px;
    margin: 10px;
  }
  
.product-actions {
    justify-content: right;
    display: flex;
}

.invoice-title {
    text-align: initial;
}


  .inline-info-three {
    width: 100%;
    display: flex;
    gap: 10px
}
  
  .invoice-item-info-item {
    width: 210px;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  
  .invoice-item-image {
    height: 100%;
    width: auto;
    object-fit:fill;
    margin-left: -10px;
  }
  
  .invoice-item-image-container {
    width: 120px;
    overflow: hidden;
  }
  