.modal-container {
    padding: 0 10px;
}

.modal-product-form {
    width: 100%;
}

.modal-form-top {
    width: 100%;
    display: grid;
    gap: 10px;
    grid-template-columns: 5fr;
}

.modal-form-bottom {
    padding: 10px;
    width: 100%;
    margin-top: 20px;
    border: 1px solid black;
}


.modal-form-left{
    padding: 10px;
    border: 1px solid black;
}

.modal-multi-item {
    border: 1px solid black;
    border-radius: 2px;
    width: 100px;
}

.modal-multi-container {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0px 10px;
}

.modal-description_box {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin: 0 -10px;
    padding: 10px;
}

.modal-supplier_link_box {
    border-top: 1px solid black;
    margin: 0 -10px;
    padding: 10px;
}

.modal-stock-box {
    padding: 15px 10px;
    border-top: 1px solid black;
    margin: 0 -10px;
}

.modal-inline-inputs {
    
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px
}

.modal-product-header-save {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}



.modal-button-margin {
    margin-right: 10px;
}

.modal-input-width {
    width: 100%;
}

.modal-left-align-paragraph {
    text-align: left;
}

.modal-plus-outlined-margin {
    margin-top: 8px;
}

