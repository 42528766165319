.file-preview-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .file-preview-item {
    width: 300px;
    height: 300px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .file-preview-image {
    max-width: 300px;
    max-height: 300px;
  }
  
  .remove-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    margin-top: 5px;
    cursor: pointer;
  }
  
  .remove-button:hover {
    background-color: #c0392b;
  }
  

  .browse-button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .browse-button:hover {
    background-color: #2980b9;
  }

  