.container {
    padding: 0 10px;
}

.product-form {
    width: 100%;
}

.form-top {
    width: 100%;
    display: grid;
    gap: 10px;
    grid-template-columns: 3fr 2fr;
}

.form-bottom {
    width: 100%;
    margin-top: 20px;
    border: 1px solid black;
}

.form-left, .form-right {
    padding: 10px;
    border: 1px solid black;
}

.multi-item {
    border: 1px solid black;
    border-radius: 2px;
    width: 100px;
}

.multi-container {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0px 10px;
}

.description_box {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin: 0 -10px;
    padding: 10px;
}

.supplier_link_box {
    border-top: 1px solid black;
    margin: 0 -10px;
    padding: 10px;
}

.description_box-cogs {
    border-bottom: 1px solid black;
    margin: 0 -10px;
    padding: 10px;
}

.measurements-box {
  padding: 15px 10px;
  border-bottom: 1px solid black;
  margin: 0 -10px;
}

.stock-box {
    padding: 15px 10px;
    border-top: 1px solid black;
    margin: 0 -10px;
}

.inline-inputs {
    
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px
}

.inline_three_inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px
}

.product-header-save {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.product-group-title {
    font-size: 14px;
}

.product-group-box{
    padding-top: 15px;
    padding: 5px;
    text-align: left;
}
.product-group-container {
    border: 1px solid black;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    border-radius: 4px;
    min-height: 180px;
}
.product-group-item {
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    width: 100px;
    height: min-content;
    margin: 5px;
    padding: 0px 5px 0px;
}
.product-group-item-color{
    font-weight: bold;
}
.important-x {
    color: red;
}

.product-group-item-X {
    text-align: right;
    font-weight: bolder;
    font-size: large;
    cursor: pointer;
    width: min-content;
    align-self: flex-end;
}

.product-group-add {
    margin-top: 5px;
    display: inline-flex;
    justify-content: stretch;
    width: 100%;
}

.product-group-add button {
    margin-left:5px;
}

.button-margin {
    margin-right: 10px;
}

.input-width {
    width: 100%;
}

.left-align-paragraph {
    text-align: left;
}

.plus-outlined-margin {
    margin-top: 8px;
}

.checkbox-container {
    display: flex;
    align-items: center;

}

.checkbox-container label {
    display: flex;
    align-items: center;
    margin-right: 10px; 
    margin-bottom: 10px;
}

.checkbox-container input[type="checkbox"] {
    margin-right: 5px; 
}

.select-and-add {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: flex-start;
}

.product-tabs {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    font-size: large;
    margin-left: 1rem;
    cursor: pointer;
    text-shadow: 0 0 1px black;
}

.selected {
    color: rgb(74, 117, 244);
}

.vat-warning{
    display: flex;
    flex-direction: row;
    color: red;
}