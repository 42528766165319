.language-selector {
    width: 120px;
    height: 30px;
    margin-right: 20px;
}

.language-selector > .selector {
    width: 100%;
    height: 100%;
}

.flag {
    margin-top: -4px;
}