.returns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.returns-table {
    width: 80%;
    margin-top: 1rem;
    span {
        font-weight: bolder !important;
        font-size: 1rem !important;
        cursor: pointer;
    }
}

.returns-table .ant-table-tbody > tr.last-row > td {
    background-color: #f0f0f0 !important; 
    border-color: #d9d9d9 !important;
}
  
.returns-table .ant-table-tbody > tr.last-row > td:first-child {
    background-color: #f0f0f0 !important; 
    border-color: #d9d9d9 !important;
}

.returns-header {
    text-align: left;
    padding: 10px;
}

.all-returns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

.single-return {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px; 
    gap: 1rem;
}

.single-return-card {
    margin-bottom: 20px;

    img {
        width: 180px;
        height: auto;
        object-fit: cover;
        margin: 10px auto;
        border: 0.2px solid lightgray;
        border-radius: 1rem;
    }
}

.return-products-header {
    text-align: left;
    padding: 10px;
}

.ant-descriptions-item-label {
    font-weight: bold;
}

.returns-table .ant-table-tbody > tr.first-row > td {
    background-color: #f0f0f0 !important; 
    border-color: #d9d9d9 !important;
    font-weight: bold;
}
  
.returns-table .ant-table-tbody > tr.first-row > td:first-child {
    background-color: #f0f0f0 !important; 
    border-color: #d9d9d9 !important;
    font-weight: bold;
}

.return-filters {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}