.list-new-product-item {
    margin-top: 5px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 3fr 10fr 4fr 4fr 6fr 2fr 2fr 2fr;
    grid-template-areas: " B-check B-image B-product B-status B-code B-vendor B-price";
    height: 75px;
    border: 1px solid black;
    padding: 0 5px;
    cursor: pointer;
  }
  .list-new-product-item:hover{
    background-color: rgb(248, 225, 225);
  }
  
  .list-new-product-item-check {
    grid-area: B-check;
  }
  .list-new-product-item-image {
    grid-area: B-image;
  }
  .list-new-product-item-product {
    grid-area: B-product;
  }
  .list-new-product-item-status {
    grid-area: B-status;
  }
  .list-new-product-item-code {
    grid-area: B-code;
  }
  .list-new-product-item-vendor {
    grid-area: B-vendor;
  }
  .list-new-product-item-price {
    grid-area: B-price;
  }
  .list-new-product-table-trash {
    grid-area: B-trash;
  }
  .list-new-product-table-blacklist {
    grid-area: B-blacklist;
  }