.container {
    padding: 0 10px;
}

.product-form {
    width: 100%;
}

.form-top {
    width: 100%;
    display: grid;
    gap: 10px;
    grid-template-columns: 3fr 2fr;
}

.form-bottom {
    width: 100%;
    margin-top: 20px;
    border: 1px solid black;
}


.button-margin {
    margin-right: 10px;
}

.input-width {
    width: 100%;
}

.left-align-paragraph {
    text-align: left;
}

.plus-outlined-margin {
    margin-top: 8px;
}

.single-image {
    width: 104px;
    height: 104px;
    padding: 7px;
    border: 0.5px solid rgb(201, 201, 201);
    position: absolute;

    
}

.single-image img {
    object-fit: contain;
}

.single-image:hover .shadow-img {
    opacity: 0.6;
}

.single-image:hover img {
    opacity: 0.6;
}
    
.main-img {
    box-shadow: 0 0 4px 2px green;
}

.shadow-img {
    background-color: rgb(174, 174, 174);
    width: 100%;
    height: 100%;
    position: relative;
    bottom: 86px;
    left: 0;
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.main-selector {
    width: 40%;
    height: 40%;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: green;
}

.trash-selector {
    color: red;
    width: 40%;
    height: 40%;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}