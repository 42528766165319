.list-coupons-container {
    padding: 0 10px;
  }
  .list-coupons-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .list-coupons-filter {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  
  .coupon-filter-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .three-grid {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
  
  .search {
    width: 40%
  }
  
  .coupon-filter-form div {
    width: 120px;
  }
  
  .list-coupons-table-head {
    background-color: rgb(194, 194, 194);
    display: grid;
    grid-template-columns: 50px repeat(6, 1fr) 30px;
    gap: 10px;
    padding: 10px 0;
  }
  